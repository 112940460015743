<template>
  <section >
    <v-breadcrumbs :items="items">
      <template v-slot:title="{ item }">
        {{ $t(item.title) }}
      </template>
    </v-breadcrumbs>
    <section class="position-relative" style="height: 100vh;display: grid;
    place-items: center;"
    v-if="loading"
   >

<section class="cate">
</section>
    <img :src="info" width="250">

    </section>
    <section v-else>
      <v-row class="my-4"  v-if="offers.length>0">
      <v-col cols="12" md="4" v-for="(item, index) in offers" :key="index">
        <v-card class="mb-3 elevation-0" style="height: 100%" @click="gotoproduct(item.id)">
          <img :src="item.image" alt="" v-if="item.image" class="w-100 rounded" height="325"/>
          <img :src="cardimg" alt="" v-else class="w-100 rounded" /> <div class="pa-2">
            <p  style="cursor: pointer; width: 90%">
              {{ item.title }}
            </p>
            <p>
              {{ $t('End_show') }}  :
              {{ item.expire_date }}
            </p>
          </div>
          <div class="text-center" v-if="item.message">
            <span
              style="background: #ffe0b0; color: #eb001b; border-radius: 20px"
              class="px-3"
            >
              {{ item.message }}
            </span>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="hint my-7 py-2" elevation="0" v-else>
        <v-card-text>
          {{ $t("nooffernow") }}
         
        </v-card-text>
      </v-card>
    </section>
   
  </section>
 

</template>
<script>
import CrudDataService from "@/Service/CrudDataService";
import cardimg from "../../../src/assets/image/offer.jpg";
import info from "../../assets/image/Into.gif"

export default {
  data: () => ({
info,
    loading:false,
    texts: [1, 2, 3],
    cardimg: cardimg,
    offers: [],
    items: [
      {
        title: "Home",
        disabled: false,
        href: "/",
      },
      {
        title: "Best_offers",
        disabled: false,
        href: "/offers",
      },
    ],
  }),
  methods: {
    async alloffers() {
      this.loading = true; // Start loading
      try {
        let res = await CrudDataService.getAll("offers");
      this.offers = res.data.data;
      
 
}catch (error) {
console.error("Failed to fetch data:", error);
// Handle error
} finally {
this.loading = false; // End loading regardless of success or failure
}
    
   
    },
    gotoproduct(params){
      this.$router.push({ name: "ProductOffers", params: { params } });
    },
  },
  mounted() {
    this.alloffers();
  },
};
</script>
<style>
.v-breadcrumbs {
  color: #8b96a5;
}
</style>
<style scoped>
.hint {
  background: #03dac5;
  color: white;
  text-align: center;
}
.hint .v-card-text {
  font-size: 18px;
}
</style>