

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1 class="text-center font-weight-medium">{{ $t('Profile') }}</h1>
      </v-col>
    </v-row>
    <v-row class="prof">
      <v-col cols="12" class="pa-0">
        <div class="d-flex align-center justify-space-between pa-5 cursor"  @click="navigateTo('/update-profile')">
          <span class="my-4 text-h5 font-weight-light">{{ $t('personal_data') }} </span>
          <v-icon size="34">mdi-chevron-left</v-icon>
        </div>
        <v-divider class="mx-4"></v-divider>
      </v-col>
      <v-col cols="12" class="pa-0">
        <div class="d-flex align-center justify-space-between pa-5 cursor" @click="navigateTo('/cart/lastorder')">
          <span class="my-4 text-h5 font-weight-light" >{{ $t('purchases') }} </span>
          <v-icon size="34">mdi-chevron-left</v-icon>
        </div>
        <v-divider class="mx-4"></v-divider>
      </v-col>
      <!-- <v-col cols="12" class="pa-0">
        <div class="d-flex align-center justify-space-between pa-5 cursor" @click="navigateTo('/contact-us')">
          <span class="my-4 text-h5 font-weight-light"> {{ $t('Contact') }} </span>
          <v-icon size="34">mdi-chevron-left</v-icon>
        </div>
        <v-divider class="mx-4"></v-divider>
      </v-col> -->
      <v-col cols="12" class="pa-0" v-for="item in items" :key="item.id">
        <div class="d-flex align-center justify-space-between pa-5 cursor" @click="gotopage(item.id)">
          <span class="my-4 text-h5 font-weight-light">{{ item.title }}  </span>
          <v-icon size="34">mdi-chevron-left</v-icon>
        </div>
        <v-divider class="mx-4"></v-divider>
      </v-col>
      <v-col cols="12" class="pa-0">
        <div @click="logout" class="pa-5 cursor">
          <span class="my-4 text-h5 font-weight-light">{{ $t('logout') }}  </span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { ref } from "vue";
import { useAuth } from "@/http-common";
import CrudDataService from '@/Service/CrudDataService';
export default {
  data() {
    return {
      items: [],
    };
  },
  methods: {
    gotopage(id){
        this.$router.push({ name: "Page", params: { id } })
    },
    navigateTo(path){
      this.$router.push(path)
    },
    async logout() {
      try {
        let response = await CrudDataService.create("logout",'');
        this.$router.push({ name: "home" }).then(() => {
          const { clearToken } = useAuth();
          clearToken();
          window.location.reload();
        });
      } catch (error) {
        console.log(error);
      }
    },
    async allpages() {
      let res = await CrudDataService.getAll("pages");
      this.items=res.data.data;
    },
  },
  mounted() {
    this.allpages();
  },
};
</script>
<style scope>
.cursor {
  cursor: pointer;
  color: #455A64;
}
</style>
