<template>
    <v-card class="mx-auto my-3 pa-4 ">
        <h5 class="pb-3"> {{ $t('Other_products') }}</h5>
      <v-row>
        <v-col cols="12" lg="2" sm="4" v-for="(item,i) in items" :key="i">
          <v-img
          class="itempro"
            :src="item.cover_image"
            cover
          ></v-img>
          <p>
            {{ item.name }}
          </p>
          <span style="color:#BDBDBD" v-if="item.current_price">
            {{ Math.trunc(item.current_price) }}
          </span>
          <span style="color:#BDBDBD" v-else>
            {{ Math.trunc(item.base_price) }}
          </span>
        </v-col>
    
      </v-row>
    </v-card>
  </template>
  <script>
import CrudDataService from '@/Service/CrudDataService';

  export default {
    data() {
      return {
        items:[]
      };
    },
    methods:{
      async allproduct(){
      let res= await CrudDataService.getAll('products');
      this.items=res.data.data.data.slice(0, 6)
    },
  
    },
    mounted(){
    this.allproduct()
  }
  };
  </script>
<style>
.itempro{
    height: 130px;
    border-radius: 6px;
    background: #eee;
}
</style>