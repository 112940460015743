<template>
  <section>
    <div
      class="d-flex justify-space-between align-center px-3 tabicon pa-3 bg-white rounded mb-3"
    >
      <p>
        {{ $t('all_products') }} 
        </p>

      <v-tabs v-model="tab" centered stacked>
        <v-tab value="one" >
          <v-icon>mdi-menu</v-icon>
        </v-tab>

        <v-tab value="two">
          <v-icon>mdi-view-grid</v-icon>
        </v-tab>
      </v-tabs>
    </div>
    <v-window v-model="tab">
      <v-window-item value="one" v-if="productfil.length>0">
        <v-card class="mb-3" v-for="(product, index) in productfil" :key="index">
          <v-row>
            <v-col cols="12" sm="4" xs="12">
              <v-img :src="product.cover_image" height="222" style="object-fit:fill !important"></v-img>
            </v-col>
            <v-col cols="12" sm="8" xs="12">
              <div class="pa-3 pr-3 pb-0">
                <div class="d-flex justify-space-between align-center">
                  <p @click="detail(product.id)" style="cursor: pointer">

                    {{ product.name }}
                  </p>
                  <v-icon
                    :icon="getIcon(product)"
                    @click="toggleHeart(product)"
                    style="
                      color: #0d6efd;
                      border: 1px solid #dee2e7;
                      padding: 15px 15px 14px 15px;
                      border-radius: 4px;
                      box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.08);
                    "
                  ></v-icon>
                </div>
                <v-card-text  class="py-1 px-0" v-if="product.discounted_price">
                  <p v-if="product.base_price" class="text-decoration-line-through text-red ">
                  {{
                  Math.trunc(product.base_price)
                }}
                {{ $t('rs') }}

                </p>
                <p >
                  {{
                  Math.trunc(product.discounted_price)
                }}
                {{ $t('rs') }}

                </p>
               </v-card-text>
               <v-card-text  class="py-1 px-0" v-else>
                  <p >
                  {{
                  Math.trunc(product.base_price)
                }}
                {{ $t('rs') }}

                </p>
                
               </v-card-text>
                
                <div class="d-flex flex-sm-row flex-column">
                  <v-rating
                    :model-value="product.rating"
                    @update:model-value="
                      updateRating(product.id, $event)
                    "
                    color="amber"
                    density="compact"
                    hover
                    size="small"
                    class="px-0"
                  ></v-rating>
                  <p style="color: #ff9017" v-if="product.rating">
                    {{ product.rating }}
                  </p>
                  <v-icon
                    icon="mdi-circle-medium"
                    color="#DEE2E7"
                    class="d-sm-inline d-none"
                    v-if="product.total_orders_quantity"
                  ></v-icon>
                  <p
                    style="color: #8b96a5"
                    v-if="product.total_orders_quantity"
                  >
                <v-icon icon="mdi-basket-outline"></v-icon>

                    {{ product.total_orders_quantity }}
                  </p>
                </div>

                <v-card-text class="line_text"> {{ 
                  product.description_without_html }}</v-card-text>
                <v-card-actions class="px-0">
                  <v-btn
                    style="
                      color: #41D3BD;
                      border: 1px solid #41D3BD;
                      border-radius: 20px;
                    "
                  @click="addcart(product.id)"
                    > {{ $t('Add_cart') }}
                    <v-icon icon="mdi-cart-outline" class="px-3"></v-icon>
                  </v-btn>
                </v-card-actions>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-window-item>
      <v-window-item value="one" v-else>
        <v-card class="mb-3" v-for="(product, index) in products" :key="index">
          <v-row>
            
            <v-col cols="12" sm="4" xs="12">
              <v-img :src="product.cover_image" height="222" style="object-fit:fill !important"></v-img>
            </v-col>
            <v-col cols="12" sm="8" xs="12">
              <div class="pa-3 pr-3 pb-0">
                <div class="d-flex justify-space-between align-center">
                  <p @click="detail(product.id)" style="cursor: pointer">
                    {{ product.name }}
                  </p>
                  <v-icon
                    :icon="getIcon(product)"
                    @click="toggleHeart(product)"
                    style="
                      color: #0d6efd;
                      border: 1px solid #dee2e7;
                      padding: 15px 15px 14px 15px;
                      border-radius: 4px;
                      box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.08);
                    "
                  ></v-icon>
                </div>
              
                <v-card-text  class="py-1 px-0" v-if="product.discounted_price">
                  <p v-if="product.base_price" class="text-decoration-line-through text-red ">
                  {{
                  Math.trunc(product.base_price)
                }}
                {{ $t('rs') }}

                </p>
                <p >
                  {{
                  Math.trunc(product.discounted_price)
                }}
                {{ $t('rs') }}

                </p>
               </v-card-text>
               <v-card-text  class="py-1 px-0" v-else>
                  <p >
                  {{
                  Math.trunc(product.base_price)
                }}
                {{ $t('rs') }}

                </p>
                
               </v-card-text>
                <div class="d-flex flex-sm-row flex-column">
                  <v-rating
                    :model-value="product.rating"
                    @update:model-value="updateRating(product.id, $event)
                    "
                    color="amber"
                    density="compact"
                    hover
                    size="small"
                    class="px-0"
                  ></v-rating>
                  <p style="color: #ff9017" v-if="product.rating">
                    {{ product.rating }}
                  </p>
                  <v-icon
                    icon="mdi-circle-medium"
                    color="#DEE2E7"
                    class="d-sm-inline d-none"
                    v-if="product.total_orders_quantity"
                  ></v-icon>
                  <p
                    style="color: #8b96a5"
                    v-if="product.total_orders_quantity"
                  >
                <v-icon icon="mdi-basket-outline"></v-icon>

                    {{ product.total_orders_quantity }}
                  </p>
                </div>

                <v-card-text class="line_text"> {{ product.description_without_html }}</v-card-text>
                <v-card-actions class="px-0">
                  <v-btn
                    style="
                      color: #41D3BD;
                      border: 1px solid #41D3BD;
                      border-radius: 20px;
                    "
                  @click="addcart(product.id)"
                    >{{ $t('Add_cart') }}  
                    <v-icon icon="mdi-cart-outline" class="px-3"></v-icon>
                  </v-btn>
                </v-card-actions>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-window-item>
      <v-window-item value="two" v-if="productfil.length>0">
        <v-row>
          <v-col
            cols="12"
            md="4"
            v-for="(product, index) in productfil"
            :key="index"
          >
            <v-card class="mb-3 elevation-0" style="height: 100%">
              <v-img :src="product.cover_image" height="222" style="object-fit:fill !important"></v-img>
              <div class="pa-3 pr-3 pb-0">
                <div class="d-flex justify-space-between align-center">
                <p @click="detail(product.id)" style="cursor: pointer">
                  {{ product.name }}
                </p>
                <v-icon
                  :icon="getIcon(product)"
                  @click="toggleHeart(product)"
                  style="
                    color: #0d6efd;
                    border: 1px solid #dee2e7;
                    padding: 15px 15px 14px 15px;
                    border-radius: 4px;
                    box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.08);
                  "
                ></v-icon>
              </div>

              <v-card-text  class="py-1 px-0" v-if="product.discounted_price">
                  <p v-if="product.base_price" class="text-decoration-line-through text-red ">
                  {{
                  Math.trunc(product.base_price)
                }}
                {{ $t('rs') }}

                </p>
                <p >
                  {{
                  Math.trunc(product.discounted_price)
                }}
                {{ $t('rs') }}

                </p>
               </v-card-text>
               <v-card-text  class="py-1 px-0" v-else>
                  <p >
                  {{
                  Math.trunc(product.base_price)
                }}
                {{ $t('rs') }}

                </p>
                
               </v-card-text>

              <div class="d-flex flex-column">
                <v-rating
                  color="amber"
                  density="compact"
                  
                  readonly
                  size="small"
                v-model="product.rating"
                  class="px-0"
                  hover
                  @update:model-value="updateRating(product.id, $event)"
                ></v-rating>
                <p style="color: #ff9017" v-if="product.rating">
                  {{ product.rating }}
                </p>
                <v-icon
                  icon="mdi-circle-medium"
                  color="#DEE2E7"
                  class="d-sm-inline d-none"
                  v-if="product.total_orders_quantity"
                ></v-icon>
                <p style="color: #8b96a5" v-if="product.total_orders_quantity">
                <v-icon icon="mdi-basket-outline"></v-icon>

                  {{ product.total_orders_quantity }}
                </p>
              </div>

              <v-card-text class="py-0 line_text" >
                {{ product.description_without_html }}
              </v-card-text>
              <v-card-actions style="justify-content: center" class="px-0">
                <v-btn
                  style="
                    color: #41D3BD;
                    border: 1px solid #41D3BD;
                    border-radius: 20px;
                  "
                  @click="addcart(product.id)"
                  >  {{ $t('Add_cart') }}  
                  <v-icon icon="mdi-cart-outline" class="px-3"></v-icon>
                </v-btn>
              </v-card-actions>
              </div>
           
            </v-card>
          </v-col>
        </v-row>
      </v-window-item>
      <v-window-item value="two" v-else>
        <v-row>
          <v-col
            cols="12"
            md="4"
            v-for="(product, index) in products"
            :key="index"
          >
            <v-card class="mb-3 elevation-0" style="height: 100%">
              <v-img :src="product.cover_image" height="222" style="object-fit:fill !important"></v-img>
              <div class="pa-3 pr-3 pb-0">
              <div class="d-flex justify-space-between align-center ">
                <p @click="detail(product.id)" style="cursor: pointer">
                  {{ product.name }}
                </p>
                <v-icon
                  :icon="getIcon(product)"
                  @click="toggleHeart(product)"
                  style="
                    color: #0d6efd;
                    border: 1px solid #dee2e7;
                    padding: 15px 15px 14px 15px;
                    border-radius: 4px;
                    box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.08);
                  "
                ></v-icon>
              </div>

              <v-card-text  class="py-1 px-0" v-if="product.discounted_price">
                  <p v-if="product.base_price" class="text-decoration-line-through text-red ">
                  {{
                  Math.trunc(product.base_price)
                }}
                {{ $t('rs') }}

                </p>
                <p >
                  {{
                  Math.trunc(product.discounted_price)
                }}
                {{ $t('rs') }}

                </p>
               </v-card-text>
               <v-card-text  class="py-1 px-0" v-else>
                  <p >
                  {{
                  Math.trunc(product.base_price)
                }}
                {{ $t('rs') }}

                </p>
                
               </v-card-text>

              <div class="d-flex flex-column">
                <v-rating
                  :model-value="product.rating"
                  color="amber"
                  density="compact"
                  
                  hover
                  size="small"
                  class="px-0"
                  @update:model-value="updateRating(product.id, $event)"

                ></v-rating>
                <p style="color: #ff9017" v-if="product.rating">
                  {{ product.rating }}
                </p>
                <v-icon
                  icon="mdi-circle-medium"
                  color="#DEE2E7"
                  class="d-sm-inline d-none"
                  v-if="product.total_orders_quantity"
                ></v-icon>
                <p style="color: #8b96a5" v-if="product.total_orders_quantity">
                <v-icon icon="mdi-basket-outline"></v-icon>
                  {{ product.total_orders_quantity }}
                </p>
              </div>

              <v-card-text class="py-0 line_text" >
                {{ product.description_without_html }}
              </v-card-text>
              <v-card-actions style="justify-content: center">
                <v-btn
                  style="
                    color: #41D3BD;
                    border: 1px solid #41D3BD;
                    border-radius: 20px;
                  "
                  @click="addcart(product.id)"
                  > {{ $t('Add_cart') }}  
                  <v-icon icon="mdi-cart-outline" class="px-3"></v-icon>
                </v-btn>
              </v-card-actions>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>
    <v-snackbar v-model="showSnackbar" color="teal-accent-4">
   {{ $t('Added_successfully') }}
    <template v-slot:action="{ attrs }">
      <v-btn color="pink" v-bind="attrs" @click="showSnackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
  <!-- <v-pagination
        class="paginate"
        v-model="page"
        :length="paginationfil.last_page"
        :total-visible="5"
        @update:model-value="productfilter"
        v-if="productfil.length > 0"
      >
     </v-pagination> -->
     <div  v-if="productfil.length > 0">

     </div>
  <v-pagination
        class="paginate"
        v-model="page"
        :length="pagination.last_page"
        :total-visible="5"
        @update:model-value="allproduct()"
        v-else-if="products.length > 0"
      ></v-pagination>
     
       
      
  </section>
</template>
<script>
import CrudDataService from "../../Service/CrudDataService";
import cam from "../../assets/image/pngwin.png";
export default {
  props: {
    productfil: Array,
    paginationfil:String, // Assuming paginationfil is an object based on your usage
  },
  data() {
    return {
      tab: null,
      pngwing: cam,
      rating: 4.5,
      products: [],
      pagination:[],
      showSnackbar :false,
      page: 1,

    };
  },
  methods: {
  async addcart(id){
    let res=await CrudDataService.create(`cart`,{
        product_id: id,
        quantity: 1,
      })
      if (res.data.success) {
      this.showSnackbar=true
    }
    },
    getIcon(product) {
      return product.isfavourite ? "mdi-heart" : "mdi-heart-outline";
    },
    detail(id) {
      this.$router.push({ name: "Detail", params: { id } });
    },
    async allproduct() {
      if (this.$route.params.params) {
        let res = await CrudDataService.getAll(`products?filter[cats_id]=${this.$route.params.params}&page=${this.page}`);
      this.products = res.data.data.data;
      this.pagination = res.data.data;
      }else{
        let res = await CrudDataService.getAll(`products?page=${this.page}`);
      this.products = res.data.data.data;
      this.pagination = res.data.data;
      }
    },
    async toggleHeart(product) {
      let res = await CrudDataService.create(
        `products/${product.id}/favorites`,
        ""
      ).then(() => {
        product.isfavourite = !product.isfavourite;
      });
    },
    async updateRating(productId, rating) {
      console.log(productId, rating);
      // Call your API to update the rating
      try {
        await CrudDataService.create(`products/${productId}/reviews`, {
          rating: rating,
        });
        this.allproduct()
        // Handle success - e.g., show a message or update local state
      } catch (error) {
        // Handle error - e.g., show an error message
        console.error("Error updating rating:", error);
      }
    },
  },
  mounted() {
    this.allproduct();
  },
};
</script>
<style>
.tabicon .v-tabs--density-default.v-tabs--stacked {
  --v-tabs-height: 40px;
}
.tabicon .v-tab.v-tab {
  min-width: auto;
  padding: 0 8px;
}
.tabicon .v-tab--selected .v-tab__slider {
  opacity: 0;
}
.tabicon .v-slide-group__container {
  border: 1px solid #dee2e7;
  border-radius: 6px;
}
.tabicon .v-slide-group-item--active {
  background: #eff2f4;
}
.tabicon .v-btn:hover > .v-btn__overlay {
  opacity: 0;
}
.paginate .v-btn--icon .v-icon {
    transform: rotateY(180deg);
}
[dir="ltr"] .paginate .v-btn--icon .v-icon {
    transform: none;
}
.line_text{
  padding: 0 !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
