<template>
  <v-row class="mt-4">
    <v-col cols="12" >
        <h2>
            {{ $t('Categories') }}
        </h2>
</v-col>
    <v-col cols="6" lg="2" md="3" sm="6" justify="center" align="center" v-for="(item,i) in items" :key="i"
    data-aos="fade-down-left"
    :data-aos-delay="200*i">
      <v-card style="background-color: #41D3BD80" class="pa-4 d-flex" v-if="item.image" @click="gotoproduct(item.id)" height="180">
        <v-img :src="item.image" class="rounded"></v-img>
      </v-card>
      <h3 class="pt-2 font-weight-medium">
 {{item.name}}
        </h3>
    </v-col>
 
  </v-row>
</template>
<script>
import CrudDataService from '@/Service/CrudDataService';

export default {
  data: () => ({
    items:[]
  }),
  methods:{
    gotoproduct(params){
      this.$router.push({ name: "Product", params: { params } });
    },
    async allcategories() {
      let res = await CrudDataService.getAll("categories");
      this.items = res.data.data.data.slice(0, 6);
      
    },
  },
  mounted(){
    this.allcategories()
  }
};
</script>
