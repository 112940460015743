<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <v-card style="background: #fff" class="pa-3 rounded-0">
    <v-row class="text-center">
      
      <!-- <v-col cols="12" lg="3" md="12" class="pb-0">
        <div style="background-color: #03dac542" class="pa-3 mb-2">
          <div class="d-flex align-center pa-3">
            <v-avatar :image="logo"></v-avatar>
            <h3 class="px-2 font-weight-medium">{{ $t('Welcome') }}</h3>
          </div>
          <div v-if="unLogged">
            <v-btn
              width="100%"
              class="rounded-0 mb-2"
              color="#127FFF"
              @click="signlink"
              >{{ $t('Create_account') }} </v-btn
            >
            <v-btn
              width="100%"
              class="rounded-0"
              style="color: #0d6efd"
              @click="account"
              >
              {{ $t('LogIn') }} 
            </v-btn>
          </div>
          <h3 class="pa-2 font-weight-medium" v-else>
            {{ username }}
          </h3>
        </div>
        <div
          style="background-color: #41D3BD; height: 42%"
          class="px-2 mb-0 py-0 uptext"
        >
          <p
            class="text-group"
            style="
              line-height: 2;
              font-size: 22px;
              color: rgb(255, 255, 255);
              padding: 15px 20px;
              text-align: right;
            "
          >
           {{$t('Join_best_services')}}
          </p>
        </div>
      </v-col> -->
      <v-col cols="12"  class="pb-0">
        
        <v-carousel
          hide-delimiter-background
          :show-arrows="false"
          class="carousel caroitem"
          v-model="activeSlide"
          cycle
        >
          <v-carousel-item v-for="(slide, i) in slides" :key="i" :src="slide.image" cover>
            <!-- <v-sheet height="88%" title> -->
              <div class="d-flex fill-height justify-center align-center">
                <!-- <v-img :src="slide.image" width="100%" class="headerimg"> -->
                  <div
                    class="text-h4 text-white text-right"
                    style="position: absolute; bottom: 20%; right: 20px"
                  >
                    {{ slide.title }}
                  </div>
                <!-- </v-img> -->
              </div>
            <!-- </v-sheet> -->
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import CrudDataService from "@/Service/CrudDataService";
import image from "../../../src/assets/image/Avatar.png";
import Group from "../../../src/assets/image/Group.png";
import { useAuth } from "@/http-common";
import { ref } from "vue";
export default {
  data: () => ({
    logo: image,
    card: Group,
    slides: [],
    username: "",
    activeSlide: 0, 
    name:"ranem",
  }),
  setup() {
    const { isLoggedIn } = useAuth();
    const unLogged = ref(!isLoggedIn());
    return {
      unLogged,
    };
  },
  methods: {
    account() {
      this.$router.push({ name: "Login" });
    },
    signlink() {
      this.$router.push({ name: "SignUp" });
    },
    async useracount() {
      const { isLoggedIn } = useAuth();
      if (ref(isLoggedIn()).value) {
        let res = await CrudDataService.getAll("user/profile").then((response)=>{
          this.username =response.data.data.fname + " " + response.data.data.lname;
        }).catch(()=>{

        })
          }
    },
    async slider() {
      let response = await CrudDataService.getAll("sliders");
      this.slides = response.data.data;
    },
  },
  mounted() {
    this.useracount();
    this.slider();
  },
};
</script>
<style>
.carousel.caroitem{
  height: 385px !important;
}
.carousel .headerimg .v-img__img--contain {
  object-fit: cover;
}
.carousel .v-btn--variant-text .v-btn__overlay {
  background: transparent;
}
.carousel .v-btn__content {
  border: 1px solid #03dac5;
  border-radius: 50%;
  font-size: 8.5px;
}
.carousel .v-btn--icon .v-icon {
  color: transparent;
}
.carousel .v-responsive.v-img {
  height: 100% !important;
}


.carousel .v-carousel__controls {
  background: #fff;
}
.carousel .v-btn--icon.v-btn--density-default {
  width: 20px;
  height: 20px;
}
::v-deep .v-window-item.v-window-item--active .v-carousel-control {
  color: #03dac5;
}
::v-deep .v-window-item.v-window-item--active .v-carousel-control .v-icon {
  color: #03dac5;
}
.carousel .v-carousel__controls__item.v-btn.v-btn--active {
  color: #03dac5;
}
.carousel.caroitem .v-img__img--cover {
  object-fit: fill !important;
  height: 88%;
}
@media (max-width: 767.98px) {
  .uptext {
    height: 36% !important;
  }
  .text-group {
    font-size: 20px !important;
    padding: 10px !important;
  }
  .carousel.caroitem{
  height: 280px !important;
  
}
.carousel.caroitem .v-img__img--cover {
    object-fit: fill !important;
    height: 75%;
}
}
@media (max-width: 560px) {
  .uptext {
    height: 48% !important;
}
  .carousel .v-responsive__sizer {
    /* background: transparent; */
}
.carousel .v-responsive__content .text-h4{
  font-size: 1rem !important;
 
    bottom: 28% !important;
}
.carousel.caroitem {
    height: 200px !important;
}
}
@media (max-width: 400px) {

.carousel.caroitem {
    height: 170px !important;
}
}
</style>
