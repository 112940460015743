<template>
  <section class="">
    <div class="text-decoration-underline text-center font-weight-medium">
      {{ $t("Complete_order") }}
    </div>
    <v-row>
      <v-col cols="12" md="8">
        <section class="detailforarrived">
          <div class="text-decoration-underline text-teal-accent-4 mb-4">
            <v-icon icon="mdi-truck" color="teal-accent-4"></v-icon>
            {{ $t("Shipping_Address") }}
          </div>
          <!-- check about radio to show that -->
          <div>
            <v-radio-group
              v-model="showDiv"
              class="radioaddress"
              style="width: 67%"
            >
              <v-radio
                :label="$t('Same_address_previous')"
                value="option1"
                class="me-5 pa-4 rounded"
                color="#03DAC5"
                style="background: #fff"
              ></v-radio>
              <v-radio
                :label="$t('Add_new_address')"
                value="option2"
                class="me-5 pa-4 rounded"
                color="#03DAC5"
                style="background: #fff"
              ></v-radio>
            </v-radio-group>
          </div>
          <!-- show Section -->
          <v-row class="mb-3" v-if="showDiv === 'option1'">
            <v-col>
              <v-radio-group v-model="address" class="">
                <v-radio
                  v-for="item in items"
                  :key="item.id"
                  :label="item.address"
                  :value="item.id"
                  class="mb-3 pa-1 rounded border"
                  color="#03DAC5"
                  style="background: #fff"
                  @change="addone(item.id)"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row class="mb-3" v-if="showDiv === 'option2'">
            <v-col>
              <v-text-field
                density="compact"
                variant="solo"
                prepend-inner-icon="mdi-city"
                placeholder=" اسم العنوان "
                single-line
                hide-details
                rounded="0"
                class="search mb-3"
                v-model="formData.title"
              >
              </v-text-field>
              <v-text-field
                density="compact"
                variant="solo"
                prepend-inner-icon="mdi-map-marker"
                placeholder=" العنوان الجديد "
                single-line
                hide-details
                rounded="0"
                class="search mb-3"
                v-model="formData.address"
              >
              </v-text-field>
              <Multiselect
                label="name"
                class="mb-3"
                :searchable="true"
                :options="countries"
                placeholder="اختر البلد"
                @change="addregions($event, countries)"
              />
              <Multiselect
                label="name"
                class="mb-3"
                :searchable="true"
                :options="regions"
                placeholder="اختر الاقليم "
                v-model="formData.region_id"
                @change="addcities($event, regions)"
              />
              <Multiselect
                label="name"
                class="mb-3"
                :searchable="true"
                :options="cities"
                placeholder="اختر المدينة "
                v-model="formData.city_id"
                @change="adddistrict($event, cities)"

              />
              <Multiselect
                label="name"
                class="mb-3"
                :searchable="true"
                :options="districta"
                placeholder="اختر المدينه "
                v-model="formData.district_id"
              />
              <div style="height: 250px">
                <l-map
                  :zoom="zoom"
                  :options="mapOptions"
                  :key="0"
                  :center="center"
                  style="height: 100% !important;z-index: 00;"
                  @click="get"
                >
                  <l-tile-layer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  ></l-tile-layer>
                  <l-marker v-if="markerPosition" :lat-lng="markerPosition"></l-marker>
                </l-map>
              </div>
              <v-Switch
                label="عنوان افتراضي"
                color="primary"
                v-model="formData.is_default"
              ></v-Switch>
              <div class="text-left">
                <v-btn
                  style="background-color: #41D3BD"
                  class="rounded-0 text-white"
                  @click="Addaddres()"
                >
                  حفظ العنوان
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <div>
            <label>
              {{ $t('expected_delivery') }}
            </label>
           
          <v-text-field
                density="compact"
                variant="solo"
                single-line
                hide-details
                rounded="0"
                class="my-3 datestyle"
                type="date"
                v-model="expected_delivery_date"
              >
              </v-text-field>
          </div>
          
          <!-- <div class="text-decoration-underline text-teal-accent-4 mb-4">
      <v-icon icon="mdi-calendar-range" color="teal-accent-4"></v-icon>
      اختيار الميعاد المناسب لك
    </div> 
    <v-date-picker show-adjacent-months no-title class="py-2" ok-text="حفظ">
    </v-date-picker>
    -->
          <v-snackbar
            v-model="showSnackbar"
color="#41D3BD"
          >
            تمت الاضافة بنجاح
            <template v-slot:action="{ attrs }">
              <v-btn color="pink" v-bind="attrs" @click="showSnackbar = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </section>
        <label>{{ $t("Add_your_comments") }}</label>
        <v-textarea
          auto-grow
          class="mt-3"
          density="compact"
          variant="solo"
          rows="5"
          row-height="25"
          shaped
          v-model="note"
        ></v-textarea>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="mx-auto my-12 pb-2 rounded-0" max-width="374">
          <v-card-item>
            <v-card-title
              class="d-flex justify-space-between"
              style="color: #505050"
            >
              <p>{{ $t("total_amount") }}:</p>
              <p>
                {{ dataitem.sub_total }}
                {{ $t("rs") }}
              </p>
            </v-card-title>
            <v-card-title
              class="d-flex justify-space-between"
              style="color: #505050"
            >
              <p>{{ $t("discount") }} :</p>
              <p class="text-red">{{ dataitem.total_discount }} ر.س</p>
            </v-card-title>
            <v-card-title
              class="d-flex justify-space-between mb-3"
              style="color: #505050"
            >
              <p>{{ $t("shipping") }} :</p>
              <p class="text-teal-accent-4">
                {{ dataitem.shipping }} {{ $t("rs") }}
              </p>
            </v-card-title>
            <v-divider class="mb-1"></v-divider>
            <v-card-title class="d-flex justify-space-between text-black">
              <p>
                {{ $t("final_price") }}
              </p>
              <p>
                {{ dataitem.total }}
                {{ $t("rs") }}
              </p>
            </v-card-title>
          </v-card-item>

          <v-card-actions>
            <!-- <v-btn
        class="text-none text-white w-100 pay"
        height="50"
        color="teal-accent-4"
        rounded="0"
        variant="flat"
        style="font-size: 22px"
      >
        الدفع
      </v-btn> -->
          </v-card-actions>
          <!-- <div class="d-flex justify-center pb-3 pt-2">
      <img :src="payment" alt="" class="mx-1" />
      <img :src="visa" alt="" class="mx-1"/>
      <img :src="ppp" alt="" class="mx-1"/>
      <img :src="pay" alt="" class="mx-1"/>
      <img :src="payments" alt="" class="mx-1"/>
    </div> -->
        </v-card>
      </v-col>
      <v-col cols="12" class="mb-5">
        <v-btn
          class="text-none text-white w-100 pay"
          height="50"
          color="teal-accent-4"
          rounded="0"
          variant="flat"
          style="font-size: 22px;opacity: .7;"
          @click="complete()"
        >
          {{ $t("Complete_order") }}
        </v-btn>
        <div class="text-red">{{ message ? message : "" }}</div>
        <v-dialog v-model="dialog" width="auto">
          <Popup />
        </v-dialog>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
import CrudDataService from "@/Service/CrudDataService";
import Popup from "./Popup.vue";
import payment from "../../assets/image/payment.png";
import pay from "../../assets/image/pay.png";
import payments from "../../assets/image/payments.png";
import ppp from "../../assets/image/ppp.png";
import visa from "../../assets/image/visa.png";
import Multiselect from "@vueform/multiselect";
export default {
  data() {
    return {
      zoom: 4,
      center: [24.774265, 46.738586],
      mapOptions: {
        zoomControl: true,
        scrollWheelZoom: false,
        touchZoom: false,
        doubleClickZoom: true,
        boxZoom: false
      },
      countries: [],
      cities: [],
      regions: [],
      districta:[],
      payment,
      pay,
      payments,
      ppp,
      visa,
      message: "",
      dialog: false,
      showDiv: null,
      address: "",
      items: [],
      showSnackbar: false,
      formData: {
        addres: "",
        title: "",
        is_default: false,
        latitude:'',
        longitude:'',
        city_id:'',
        region_id:'',
        district_id:''
      },
      dataitem: [],
      address_id: "",
      payment_method_id: 1,
      note: "",
      expected_delivery_date:null
    };
  },
  components: {
    Popup,
    Multiselect,
    LMap,
    LTileLayer,
    LMarker,
  },
  methods: {
    async country() {
      const result = await CrudDataService.getAll("countries");
      this.countries = result.data.data.map((country) => ({
        value: country.id,
        name: country.name,
        regions: country.regions,
      }));
    },
    addregions(events, countries) {
      countries.forEach((ele) => {
        if (events === ele.value) {
          this.regions = ele.regions.map((reg) => ({
            value: reg.id,
            name: reg.name,
            cities: reg.cities,
          }));
        }
      });
    },
    addcities(events, regions) {
      regions.forEach((ele) => {
        if (events === ele.value) {
          this.cities = ele.cities.map((city) => ({
            value: city.id,
            name: city.name,
            districts: city.districts,
          }));
        }
      });
    },
    adddistrict(events, dis) {
      dis.forEach((ele) => {
        if (events === ele.value) {
          this.districta = ele.districts.map((dist) => ({
            value: dist.id,
            name: dist.name,
          }));
        }
      });
    },
    async allcountries() {
      let country = await CrudDataService.getAll("countries");
      console.log(country.data.data);
    },
    addone(id) {
      this.address_id = id;
    },
    async complete() {
      await CrudDataService.create("orders", {
        address_id: this.address_id,
        code: this.$route.params.coupon,
        payment_method_id: 1,
        note: this.note,
        expected_delivery_date:this.expected_delivery_date
      })
        .then((response) => {
          this.dialog = true;
          setTimeout(() => {
            this.$router.push({ name: "home" });
          }, 2000);
        })
        .catch((error) => {
          this.message = error.data.message;
        });
    },
    async addres() {
      let res = await CrudDataService.getAll("user/addresses");
      this.items = res.data.data;
    },
    async Addaddres() {
      let res = await CrudDataService.create("user/addresses", this.formData);
      if (res.data.success) {
        this.addres();

        this.showSnackbar = true;
      }
    },
    async cart() {
      let res = await CrudDataService.getAll(
        `cart?code=${this.$route.params.coupon}`
      );
      this.dataitem = res.data.data;
      // this.totalcart = res.data.data.total;
    },
    get(event) {
      this.formData.latitude=event.latlng.lat
      this.formData.longitude=event.latlng.lng
     
    }
  },
  computed: {
    markerPosition() {
      if (this.formData.latitude !== null && this.formData.longitude !== null) {
        return [this.formData.latitude, this.formData.longitude];
      }
      return null; 
    },
  },

  mounted() {
    this.cart();
    this.addres();
    this.country();
  },
};
</script>
<style>
.datestyle{
  width: 22% !important;
}
.detailforarrived .radioaddress .v-selection-control-group {
  flex-direction: row;
}

.detailforarrived .radioaddress .v-selection-control--density-default {
  flex-direction: row-reverse;
  justify-content: center;
}
.detailforarrived .v-picker.v-sheet.v-picker--with-actions {
  width: 100% !important;
}
.detailforarrived .v-picker-title {
  display: none;
}
.detailforarrived
  .v-picker
  .v-date-picker-controls__month
  .v-btn--icon.v-btn--density-default {
  background: #03dac5;
  color: #fff;
  border-radius: 9px;
  margin-right: 2px;
  margin-left: 2px;
}
.detailforarrived .v-date-picker-controls__month {
  flex-direction: row-reverse;
}
.detailforarrived .v-picker__actions .v-btn {
  min-width: 120px;
  background: #41D3BD;
  color: white;
  border-radius: 0;
}
.detailforarrived .v-picker__actions .v-btn:first-child {
  display: none;
}
@media (max-width: 1260px) {
  .datestyle{
  width: 35% !important;
}
}
@media (max-width: 950px) {
  .datestyle{
  width: 20% !important;
}
}
@media (max-width: 767.98px) {
  .datestyle{
  width: 30% !important;
}
  .radioaddress {
    width: 100% !important;
  }
}
@media (max-width: 575.98px) {
  .datestyle{
  width: 45% !important;
}
  .detailforarrived .v-picker__body {
    width: 90%;
  }
  .detailforarrived .radioaddress .v-selection-control--density-default {
    margin-bottom: 10px;
  }
  .radioaddress {
    width: 100% !important;
  }

  .detailforarrived .radioaddress .v-selection-control-group {
    flex-direction: column;
  }
  .detailforarrived .v-picker.v-sheet.v-picker--with-actions {
    width: 100% !important;
    overflow: auto;
  }
}
@media (max-width: 400px) {
  .datestyle{
  width: 58% !important;
}}
</style>
